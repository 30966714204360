export function formatPrice(price, currency) {
        let formattedPrice;
        if (currency === 'IDR') {
            formattedPrice = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price * 100);
        } else {
            formattedPrice = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(price);
        }
        return formattedPrice;
    }


// export const CURRENCIES = [
//   "EUR","USD","ARS","BRL","CHF","CZK","IDR","KZT","MXN","PHP","PLN","RON","TRY","GBP",
//   "ALL","BGN","DKK","EGP","GEL","HKR","HUF","INR","ILS","JPY","LTL","MYR","SGD","MAD",
//   "ZAR","TND","UAH","AED","VND","AUD","CAD","CNY","ANG","HKD","KRW","KWD","NZD","SGD",
//   "SVC","TH"
// ] as const;

// export type Currency = typeof CURRENCIES[number];
// export type Prices = {
//   [K in Currency]?: number | null;
// }

// export interface CatalogItem {
//   item_name: string;
//   buyer: string;
//   prices: Prices;
// }

// export interface CatalogStats {
//   totalItems: number;
//   uniqueItems: number;
//   uniqueItemNames: number;
//   uniqueBuyers: number;
//   currencyCounts: Record<Currency, number>;
//   uniquePriceCombinations: number;
//   duplicateItems: number;
//   itemsPerCurrency: Record<Currency, number>;
// }

// // Helper function to get a unique key for an item based on its name and prices
// const getItemKey = (item: CatalogItem): string => {
//   const pricesKey = CURRENCIES.map(currency => {
//     const price = item.prices[currency];
//     return price !== undefined && price !== null ? `${currency}:${price}` : '';
//   }).filter(Boolean).sort().join('|');
  
//   return `${item.item_name}|${pricesKey}`;
// };

// // Function to analyze catalog and return detailed statistics
// export const analyzeCatalog = (catalog: CatalogItem[]): CatalogStats => {
//   const uniqueKeys = new Set<string>();
//   const uniqueNames = new Set<string>();
//   const uniqueBuyers = new Set<string>();
//   const currencyCounts: Record<Currency, number> = {} as Record<Currency, number>;
//   const itemsPerCurrency: Record<Currency, number> = {} as Record<Currency, number>;

//   // Initialize currency counts
//   CURRENCIES.forEach(currency => {
//     currencyCounts[currency] = 0;
//     itemsPerCurrency[currency] = 0;
//   });

//   // Process each item
//   catalog.forEach(item => {
//     const key = getItemKey(item);
//     uniqueKeys.add(key);
//     uniqueNames.add(item.item_name);
//     uniqueBuyers.add(item.buyer);

//     // Count currencies and prices
//     CURRENCIES.forEach(currency => {
//       if (item.prices[currency] !== undefined && item.prices[currency] !== null) {
//         currencyCounts[currency]++;
//         itemsPerCurrency[currency]++;
//       }
//     });
//   });

//   // Calculate stats
//   return {
//     totalItems: catalog.length,
//     uniqueItems: uniqueKeys.size,
//     uniqueItemNames: uniqueNames.size,
//     uniqueBuyers: uniqueBuyers.size,
//     currencyCounts,
//     uniquePriceCombinations: uniqueKeys.size,
//     duplicateItems: catalog.length - uniqueKeys.size,
//     itemsPerCurrency
//   };
// };

// // Function to find unique items based on item name and price values
// export const findUniqueCatalog = (catalog: CatalogItem[]) => {
//   const uniqueMap = new Map<string, CatalogItem>();
  
//   catalog.forEach(item => {
//     const key = getItemKey(item);
//     if (!uniqueMap.has(key)) {
//       uniqueMap.set(key, item);
//     }
//   });
  
//   return Array.from(uniqueMap.values());
// };

// // Function to group items by currency with unique items only
// export const groupByCurrency = (catalog: CatalogItem[]) => {
//   const uniqueCatalog = findUniqueCatalog(catalog);
//   const result: Record<Currency, CatalogItem[]> = {} as Record<Currency, CatalogItem[]>;
  
//   CURRENCIES.forEach(currency => {
//     result[currency] = [];
//   });

//   uniqueCatalog.forEach(item => {
//     CURRENCIES.forEach(currency => {
//       if (item.prices[currency] !== undefined && item.prices[currency] !== null) {
//         result[currency].push(item);
//       }
//     });
//   });

//   // Remove empty currency groups
//   Object.keys(result).forEach(currency => {
//     if (result[currency as Currency].length === 0) {
//       delete result[currency as Currency];
//     }
//   });

//   return result;
// };
